.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 0 10%;
    gap: 50px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.info_button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.title {
    font-size: 36px;
    font-weight: var(--font-weight-normal);
    line-height: 40px;
}

.content {
    font-size: 16px;
    color: var(--primary-gray);
}

.image {
    width: 100%;
    height: 100%;
}

@media (max-width: 1024px) {
    .image {
        margin: 0 auto;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .container {
        margin: 0;
        grid-template-columns: 1fr;
    }
    .image {
        grid-row: 1;
        width: 70%;
    }
}

@media (max-width: 468px) {
    .image {
        width: 100%;
    }
}
