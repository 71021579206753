:root {
  --button-border-color: #00CCA3;
  --button-text-color: #0F0F0F;
  --button-box-shadow-color: #00d2a840;

  --primary-black: #0F0F0F;
  --primary-border-color: #DEE1E9;
  --primary-border-red: #d8000c;
  --primary-color: #00CCA3;
  --secondary-color: #ffffff33;
  --primary-gray: #697088;
  --secondary-gray: #8D8D8D;
  --primary-white: #FFFFFF;
  --primary-red: #d8000c;

  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 18px;
  --font-size-ml: 20px;
  --font-size-lg: 24px;
  --font-size-xl: 32px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --spacing-sm: 5px;
  --spacing-md: 10px;
  --spacing-lg: 20px;

  --border-width-sm: 1px;
  --border-width-md: 2px;
  --border-width-lg: 3px;

  --border-radius-sm: 4px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
}
