.container {
    width: 100%;
}

.inputs_main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.container_controls {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    gap: 15px;
}

.controls_buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

@media (max-width: 1024px) {
    .container {
        width: 100%;
    }
    .inputs_main {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 648px) {
    .container_controls {
        flex-direction: column;
    }
}