label {
    color: var(--secondary-gray);
    font-size: var(--font-size-xs);
    line-height: 20px;
}

.custom__input {
    width: 100%;
    height: 49px;
    border-radius: 0;
    background: none;
    color: var(--secondary-gray);
    font-size: var(--font-size-sm);
    border: none;
    border-bottom: var(--border-width-sm) solid var(--primary-border-color);
}

.container__input {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.custom__input:disabled {
    cursor: not-allowed;
    background-color: var(--primary-color);
}

.custom__input:hover {
    border-color: var(--primary-color);
}

.custom__input:focus {
    outline: none;
}

.errors {
    color: var(--primary-red);
    background-color: var(--primary-white);
    border: 1px solid var(--primary-border-red);
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    animation: shake 0.3s ease-in-out;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-3px); }
    100% { transform: translateX(3px); }
}
