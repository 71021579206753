.container__select {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding: 10px;
}

.custom__label {
    color: var(--secondary-gray);
    font-size: var(--font-size-xs);
    line-height: 20px;
}

.custom__select {
    -webkit-appearance: none;
    padding: var(--spacing-md) 0;
    border: none;
    background: transparent url("/public/assets/select_icon.svg") 100% 50% no-repeat;
    border-bottom: var(--border-width-sm) solid var(--primary-border-color);
    color: var(--secondary-gray);
    border-radius: 0;
    width: 100%;
}

.custom__select_option {
    background-color: var(--primary-black);
    font-size: var(--font-size-sm);
}

.custom__select:disabled {
    cursor: not-allowed;
    background-color: var(--primary-color);
}

.custom__select:hover {
    border-color: var(--primary-color);
}

.custom__select:focus {
    border-color: var(--primary-color);
    outline: none;
}

.errors {
    color: var(--primary-red);
    background-color: var(--primary-white);
    border: 1px solid var(--primary-border-red);
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    animation: shake 0.3s ease-in-out;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-3px); }
    100% { transform: translateX(3px); }
}
