.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    width: 100%;
}

.checkbox_checkMark {
    position: relative;
    display: inline-block;
    min-width: 24px;
    min-height: 24px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    margin-right: 5px;
}

.checkbox_checkMark::after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 2px;
    width: 8px;
    height: 16px;
    border: solid var(--primary-white);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

input[type='checkbox'] {
    position: absolute;
    opacity: 0;
}

input[type='checkbox']:checked + .checkbox_checkMark::after {
    display: block;
}