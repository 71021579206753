.content {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-medium);
    color: var(--primary-color);
}

@media (max-width: 1024px) {
    .content {
        font-size: var(--font-size-ml);
    }
}

@media (max-width: 480px) {
    .content {
        font-size: var(--font-size-sm);
    }
}