.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 29px 19px 28px 19px;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--button-border-color);
  background: #00000003;
  backdrop-filter: blur(12px);
  height: 260px;
}

.title {
  font-size: var(--font-size-md);
  font-weight: 600;
  line-height: 25px;
}

.content {
  text-align: center;
  color: var(--primary-gray);
  font-size: var(--font-size-sm);
  line-height: 140%;
  font-weight: var(--font-weight-normal);
}

@media (max-width: 1200px) {
  .title {
    font-size: 16px;
  }
  .content {
    font-size: var(--font-size-sm);
  }
}
