.container {
    display: flex;
    flex-direction: column;
    gap: 21px;
    margin: 0 auto;
    width: 489px;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.title {
    font-size: 40px;
    font-weight: var(--font-weight-normal);
    text-transform: uppercase;
    line-height: 48px;
}

@media (max-width: 768px) {
    .container {
       width: 380px;
    }
}

@media (max-width: 420px) {
    .container {
        width: 300px;
    }
}

@media (max-width: 340px) {
    .container {
        width: 250px;
    }
}
