.container {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--primary-white);
  font-size: 36px;
  font-weight: var(--font-weight-normal);
  line-height: 48px;
}

.text_card {
  display: grid;
  line-height: 1.5em;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;
  margin-top: 32px;
}

@media (max-width: 1024px) {
  .text_card {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}

@media (max-width: 480px) {
  .text_card {
    line-height: 140%;
  }
  .title {
    font-size: 36px;
  }
}

@media (max-width: 320px) {
  .title {
    font-size: var(--font-size-xl);
  }
}
