.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  border: var(--border-width-sm) solid var(--primary-color);
}

.container_photo img {
  width: 100%;
}

.container_description {
  padding: 24px;
  border-top: var(--border-width-sm) solid var(--secondary-color);
}

.container_description h3 {
  font-size: var(--font-size-ml);
  white-space: nowrap;
  color: var(--primary-color);
  line-height: 27px;
}

.container_description span {
  font-size: var(--font-size-md);
  color: var(--primary-gray);
  line-height: 27px;
}

.container_photo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
