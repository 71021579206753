@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,100&family=Rubik:wght@300;400&family=Victor+Mono:ital,wght@0,400;0,700;1,100&display=swap');
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

a {
  cursor: pointer;
}

body {
  line-height: 1;
  padding: 0 5%;
  background: #0F0F0F;
  color: white;
  font-family: 'Rubik', sans-serif;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 0; /* можно изменить на любой вариант цвета */
}